<template>
    <st-page
        :title="$t('TAX_CALCULATOR.TITLE')"
        backLink="/payments"
        layout="section">
        <div class="row">
            <div class="col-12 col-md-8">
                <b-card class="st-section">
                    <div class="st-section-header">
                        {{ $t('TAX_CALCULATOR.FORM_SECTION_TITLE') }}
                    </div>
                    <div class="st-section-body">
                        <form class="form" novalidate="novalidate" id="st_tax_calculator_form">
                            <div class="form-group">
                                <label>
                                    {{ fields.application_type.label }}
                                </label>
                                <st-select
                                    v-model="model[fields.application_type.name]"
                                    :ref="fields.application_type.name"
                                    :field="fields.application_type"
                                    :formValidation="fv"
                                    searchable
                                >
                                </st-select>
                            </div>
                            <div class="form-group">
                                <label class="font-size-h6">
                                    {{ fields.uat.label }}
                                </label>
                                <div>
                                    <st-select
                                        v-model="model[fields.uat.name]"
                                        :ref="fields.uat.name"
                                        :field="fields.uat"
                                        :formValidation="fv"
                                        searchable
                                    >
                                    </st-select>
                                </div>
                            </div>
                          <!--   <div class="form-group mt-4">
                                <b-form-checkbox
                                    v-model="model[fields.exemption.name]"
                                    :ref="fields.exemption.name"
                                    :id="fields.exemption.name"
                                    size="lg"
                                    :disabled="true"
                                >
                                     {{ fields.exemption.label }}
                                </b-form-checkbox>
                            </div>
                             -->
                            <div class="row">
                                <div class="col-6">
                                    <div class="form-group">
                                        <label>
                                            {{ fields.land_area.label }}
                                        </label>
                                        <st-input-text
                                            :ref="fields.land_area.name"
                                            :name="fields.land_area.name"
                                            v-model="model[fields.land_area.name]"
                                            :placeholder="$t('PLACEHOLDERS.MP')"
                                        />
                                    </div>
                                </div>
                                <div class="col-6 mt-7">
                                    <st-button
                                        variant="primary"
                                        :callback="calculate"
                                        size="large"
                                        :disabled="isLoading"
                                        :spinner="isLoading"
                                    >
                                        {{ $t('TAX_CALCULATOR.CALCULATE_BUTTON') }}
                                    </st-button>
                                </div>
                            </div>
                        </form>
                    </div>
                </b-card>
            </div>
            <div class="col-12 col-md-4">
                <b-card class="st-section">
                    <div class="st-section-header">
                        {{ $t('TAX_CALCULATOR.SUMMARY_SECTION_TITLE') }}
                    </div>
                    <div class="st-section-body">
                        <div class="summary-row mt-8" v-for="(tax, index) in applicationTaxes" :key="`tax-${index}`">
                                <div>1 x {{ `${$t('APPLICATION.SUMMARY.TAX_TEXT')} ${tax.description} (${tax.uat_name})` }}</div>
                                <div>{{ `${tax.value} ${$t('GENERAL.CURRENCY_LABEL')}` }}</div>
                        </div>
                     <!--    <div class="summary-row mt-8">
                            <div>{{ $t('TAX_CALCULATOR.SUMMARY.OTHER_TAXES_LABEL') }}</div>
                            <div>{{ otherTaxesValue }} lei</div>
                        </div> -->
                        <!-- <div class="summary-row mt-8">
                            // TO DO: add exemption once is implemented in backend
                            <div>{{ $t('TAX_CALCULATOR.SUMMARY.EXEMPTION_LABEL') }}</div>
                            <div>{{ exemptionValue }} lei</div>
                        </div> -->

                        <!-- <div class="summary-row mt-8">
                            // TO DO: add VAT once is implemented in backend
                            <div>{{ $t('TAX_CALCULATOR.SUMMARY.VAT_LABEL') }}</div>
                            <div>{{ VATValue }} lei</div>
                        </div> -->
                        <hr>
                        <div class="summary-row font-weight-bold mt-8">
                            <div>{{ $t('TAX_CALCULATOR.TOTAL') }}</div>
                            <div>{{ total }} lei</div>
                        </div>
                    </div>
                </b-card>
            </div>
        </div>
    </st-page>
</template>
<script>
import { TaxCalculatorModel } from "@/modules/payment-registry/tax-calculator-model";
import { FormSchema } from "@/shared/form/form-schema";
import { createFormValidation } from "@/shared/utils/create-form-validation";
import { mapActions, mapGetters } from "vuex";

const { fields } = TaxCalculatorModel;

const formSchema = new FormSchema([
    fields.application_type,
    fields.land_area,
    fields.exemption,
    fields.uat,
]);

export default {
    name: 'TaxCalculator',
    data() {
        return {
            fields,
            model: null,
            rules: formSchema.rules(),
            taxName: this.$t('TAX_CALCULATOR.DEFAULT_TAX_NAME'),
            taxValue: 0,
            applicationTaxes: [],
            otherTaxesValue: 0,
            exemptionValue: 0,
            VATValue: 0,
            fv: null,
            total: 0,
        }
    },
    computed: {
        ...mapGetters({
            loading: 'shared/loading',
        }),
        isLoading() {
            return this.loading['applications/calculateTaxesForApplicationType'];
        },
    },
    methods: {
        ...mapActions({
            calculateTaxesForApplicationType: "applications/form/calculateTaxesForApplicationType",
        }),
        async calculate() {
            const validate = await this.fv.validate();
            if (validate === 'Valid') {
                const payload = {
                    id: this.model.application_type,
                    land_area: this.model.land_area,
                    territorial_administrative_unit_id: this.model.uat,
                };

                let taxes = await this.calculateTaxesForApplicationType(payload);
                this.applicationTaxes = taxes.application_tax_values;
                this.taxValue = taxes?.total_application || 0;
                this.total = taxes?.total;
            }
        }
    },
    created() {
        this.model = formSchema.initialValues();
    },
    mounted() {
        this.fv = createFormValidation("st_tax_calculator_form", this.rules);
    },
}
</script>
<style lang="scss">
    .summary-row {
        display: flex;
        justify-content: space-between;
        font-size: 1.25rem;
    }
</style>
