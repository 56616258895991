import RelationToOneField from '@/shared/fields/relation-to-one-field';
import ApiService from '@/shared/services/api.service';

export class ApplicationTypeField {
    static relationToOne(name, label, rules, filterParams, children, options) {
        return new RelationToOneField(
            name,
            label,
            () => {
                return new Promise(async (resolve) => {
                    let params = {
                        query: 'is_internal ne true and is_auto eq false and status eq active and is_tax_category_conditional ne true',
                        limit: 200,
                    }

                    ApiService.query('/application-types', { params }).then(({ data }) => {
                        resolve(data);
                    });
                });
            },
            (record) => {
                if (!record) {
                    return '';
                }

                return {
                    value: record.name,
                    text: record.name,
                };
            },
            rules,
            filterParams,
            children,
            options
        );
    }
}
